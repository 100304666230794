import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Paper
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const SortableRow = ({ row, onCellChange, onDelete }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: row.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <TableRow ref={setNodeRef} style={style}>
      <TableCell style={{ width: "40px" }}>
        <IconButton {...attributes} {...listeners}>
          <DragIndicatorIcon />
        </IconButton>
      </TableCell>
      <TableCell>
        <TextField
          value={row.question}
          onChange={e => onCellChange(e, row.id)}
          fullWidth
        />
      </TableCell>
      <TableCell align="right" style={{ width: "40px" }}>
        <IconButton onClick={() => onDelete(row.id)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const CardsTable = ({ rows, setRows }) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  if (!rows || !Array.isArray(rows)) {
    return null;
  }

  const handleCellChange = (e, id) => {
    const newRows = [...rows];
    const index = rows.findIndex(row => row.id === id);
    newRows[index].question = e.target.value;
    setRows(newRows);
  };

  const handleDelete = id => {
    const newRows = rows.filter(row => row.id !== id);
    setRows(newRows);
  };

  const handleDragEnd = event => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = rows.findIndex(row => row.id === active.id);
      const newIndex = rows.findIndex(row => row.id === over.id);

      setRows(arrayMove(rows, oldIndex, newIndex));
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <SortableContext
              items={rows.map(row => row.id)}
              strategy={verticalListSortingStrategy}
            >
              {rows.map(row =>
                row && row.id ? (
                  <SortableRow
                    key={row.id}
                    row={row}
                    onCellChange={handleCellChange}
                    onDelete={handleDelete}
                  />
                ) : null
              )}
            </SortableContext>
          </TableBody>
        </Table>
      </TableContainer>
    </DndContext>
  );
};

export default CardsTable;
